import React from "react";

import globalstyles from "../../../../styles/global.module.scss";
import styles from "./partners.module.scss";
import gamersGalaxieLogo from "../../../../assets/gamers-galaxie.webp";
import comfortZoneLogo from "../../../../assets/comfort-zone.webp";

import {gamersGalaxie} from "../../../../constants/redirects";
import {comfortZone} from "../../../../constants/redirects";

const Partners = () => {


  return (
      <div className={globalstyles.wrapperContainer} id={"Partners"}>
          <div className={globalstyles.headlineOsakeTilt}>
Partners
          </div>
          <div className={styles.cardWrapper}>
              <a href={gamersGalaxie} target={"_blank"} rel={'noreferrer'}>
                  <img
                      className={styles.cardImage + " " + globalstyles.cardBorderGlow}
                      src={gamersGalaxieLogo}
                      alt={'gamers galaxie logo'}/>
              </a>
              <a href={comfortZone} target={"_blank"} rel={'noreferrer'}>
                  <img
                      className={styles.cardImage + " " + globalstyles.cardBorderGlow}
                      src={comfortZoneLogo}
                      alt={'comfort zone logo'}/>
              </a>

          </div>
      </div>
  );
};

export default Partners;
